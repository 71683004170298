<template>
  <div class="d-flex flex-wrap justify-content-end" style="gap: 0.5rem">
    <template v-for="(filter, i) in activeFilters">
      <v-slide-x-reverse-transition mode="out-in" :key="'filter-transition' + i + '_' + filter.ref">
        <div
          v-if="filter.active && filter.type == FILTER_TYPE.User.value"
          :key="'filter-type' + i + '_' + filter.ref"
        >
          <user-filter
            :ref="filter.ref"
            @close="onFilterClose(activeFilters[i])"
            v-model="optionsData[activeFilters[i].model]"
            :settings="activeFilters[i]"
          ></user-filter>
        </div>
        <div
          v-else-if="filter.active && filter.type == FILTER_TYPE.ProjectSpaceCreatedBy.value"
          :key="'filter-type' + i + '_' + filter.ref"
        >
          <project-space-created-by-filter
            :ref="filter.ref"
            @close="onFilterClose(activeFilters[i])"
            v-model="optionsData[activeFilters[i].model]"
            :settings="activeFilters[i]"
          ></project-space-created-by-filter>
        </div>
        <div
          v-else-if="filter.active && filter.type == FILTER_TYPE.ProjectSpaceUpdatedBy.value"
          :key="'filter-type' + i + '_' + filter.ref"
        >
          <project-space-updated-by-filter
            :ref="filter.ref"
            @close="onFilterClose(activeFilters[i])"
            v-model="optionsData[activeFilters[i].model]"
            :settings="activeFilters[i]"
          ></project-space-updated-by-filter>
        </div>
        <div
          v-else-if="filter.active && filter.type == FILTER_TYPE.SpaceTemplate.value"
          :key="'filter-type' + i + '_' + filter.ref"
        >
          <space-template-filter
            :ref="filter.ref"
            @close="onFilterClose(activeFilters[i])"
            v-model="optionsData[activeFilters[i].model]"
            :settings="activeFilters[i]"
          ></space-template-filter>
        </div>
        <div
          v-else-if="filter.active && filter.type == FILTER_TYPE.Level.value"
          :key="'filter-type' + i + '_' + filter.ref"
        >
          <level-filter
            :ref="filter.ref"
            @close="onFilterClose(activeFilters[i])"
            v-model="optionsData[activeFilters[i].model]"
            :settings="activeFilters[i]"
          ></level-filter>
        </div>
        <div
          v-else-if="filter.active && filter.type == FILTER_TYPE.Building.value"
          :key="'filter-type' + i + '_' + filter.ref"
        >
          <building-filter
            :ref="filter.ref"
            @close="onFilterClose(activeFilters[i])"
            v-model="optionsData[activeFilters[i].model]"
            :settings="activeFilters[i]"
          ></building-filter>
        </div>
        <div
          v-else-if="filter.active && filter.type == FILTER_TYPE.Manufacturer.value"
          :key="'filter-type' + i + '_' + filter.ref"
        >
          <manufacturer-filter
            :ref="filter.ref"
            @close="onFilterClose(activeFilters[i])"
            v-model="optionsData[activeFilters[i].model]"
            :settings="activeFilters[i]"
            :selected-manufacturers.sync="selectedManufacturers"
          ></manufacturer-filter>
        </div>
        <div
          v-else-if="filter.active && filter.type == FILTER_TYPE.ProductFamily.value"
          :key="'filter-type' + i + '_' + filter.ref"
        >
          <product-family-filter
            :ref="filter.ref"
            @close="onFilterClose(activeFilters[i])"
            v-model="optionsData[activeFilters[i].model]"
            :settings="activeFilters[i]"
            :product-families="selectedManufacturersProductFamiliesList"
          ></product-family-filter>
        </div>
        <div
          v-else-if="filter.active && filter.type == FILTER_TYPE.EquipmentCategory.value"
          :key="'filter-type' + i + '_' + filter.ref"
        >
          <equipment-category-filter
            :ref="filter.ref"
            @close="onFilterClose(activeFilters[i])"
            v-model="optionsData[activeFilters[i].model]"
            :settings="activeFilters[i]"
          ></equipment-category-filter>
        </div>
        <div
          v-else-if="filter.active && filter.type == FILTER_TYPE.Installer.value"
          :key="'filter-type' + i + '_' + filter.ref"
        >
          <installer-filter
            :ref="filter.ref"
            @close="onFilterClose(activeFilters[i])"
            v-model="optionsData[activeFilters[i].model]"
            :settings="activeFilters[i]"
          ></installer-filter>
        </div>
        <div
          v-else-if="filter.active && filter.type == FILTER_TYPE.Provider.value"
          :key="'filter-type' + i + '_' + filter.ref"
        >
          <provider-filter
            :ref="filter.ref"
            @close="onFilterClose(activeFilters[i])"
            v-model="optionsData[activeFilters[i].model]"
            :settings="activeFilters[i]"
          ></provider-filter>
        </div>
        <div
          v-else-if="filter.active && filter.type == FILTER_TYPE.Client.value"
          :key="'filter-type' + i + '_' + filter.ref"
        >
          <client-filter
            :ref="filter.ref"
            @close="onFilterClose(activeFilters[i])"
            v-model="optionsData[activeFilters[i].model]"
            :settings="activeFilters[i]"
          ></client-filter>
        </div>
        <div
          v-else-if="filter.active && filter.type == FILTER_TYPE.ProjectStatus.value"
          :key="'filter-type' + i + '_' + filter.ref"
        >
          <project-status-filter
            :ref="filter.ref"
            @close="onFilterClose(activeFilters[i])"
            v-model="optionsData[activeFilters[i].model]"
            :settings="activeFilters[i]"
          ></project-status-filter>
        </div>
        <div
          v-else-if="filter.active && filter.type == FILTER_TYPE.Role.value"
          :key="'filter-type' + i + '_' + filter.ref"
        >
          <role-filter
            :ref="filter.ref"
            @close="onFilterClose(activeFilters[i])"
            v-model="optionsData[activeFilters[i].model]"
            :settings="activeFilters[i]"
          ></role-filter>
        </div>
        <div
          v-else-if="filter.active && filter.type == FILTER_TYPE.ExternalRole.value"
          :key="'filter-type' + i + '_' + filter.ref"
        >
          <external-role-filter
            :ref="filter.ref"
            @close="onFilterClose(activeFilters[i])"
            v-model="optionsData[activeFilters[i].model]"
            :settings="activeFilters[i]"
          ></external-role-filter>
        </div>
        <div
          v-else-if="filter.active && filter.type == FILTER_TYPE.ContractType.value"
          :key="'filter-type' + i + '_' + filter.ref"
        >
          <contract-type-filter
            :ref="filter.ref"
            @close="onFilterClose(activeFilters[i])"
            v-model="optionsData[activeFilters[i].model]"
            :settings="activeFilters[i]"
          ></contract-type-filter>
        </div>
        <div
          v-else-if="filter.active && filter.type == FILTER_TYPE.ContractOrigin.value"
          :key="'filter-type' + i + '_' + filter.ref"
        >
          <contract-origin-filter
            :ref="filter.ref"
            @close="onFilterClose(activeFilters[i])"
            v-model="optionsData[activeFilters[i].model]"
            :settings="activeFilters[i]"
          ></contract-origin-filter>
        </div>
        <div
          v-else-if="filter.active && filter.type == FILTER_TYPE.ContractStatus.value"
          :key="'filter-type' + i + '_' + filter.ref"
        >
          <contract-status-filter
            :ref="filter.ref"
            @close="onFilterClose(activeFilters[i])"
            v-model="optionsData[activeFilters[i].model]"
            :settings="activeFilters[i]"
          ></contract-status-filter>
        </div>
        <div
          v-else-if="filter.active && filter.type == FILTER_TYPE.DownloadType.value"
          :key="'filter-type' + i + '_' + filter.ref"
        >
          <download-type-filter
            :ref="filter.ref"
            @close="onFilterClose(activeFilters[i])"
            v-model="optionsData[activeFilters[i].model]"
            :settings="activeFilters[i]"
          ></download-type-filter>
        </div>
      </v-slide-x-reverse-transition>
    </template>

    <v-menu rounded="md" offset-y :close-on-content-click="false">
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          style="height: 32px !important"
          color="white"
          class="text-capitalize d-flex align-center"
          height="32"
          ref="menuBtn"
        >
          <span><i class="fad fa-filters mr-2"></i></span> Filters
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item-group v-model="activeFiltersModel" multiple color="info">
          <v-list-item
            v-for="(filter, k) in activeFilters"
            :input-value="filter.active"
            :key="'filterList_' + k + '_' + filter.ref"
            @change="filterHasChanged(filter, $event)"
            class="pl-3"
            dense
          >
            <template v-slot:default="{ active }">
              <v-list-item-action class="mr-1 my-0">
                <v-checkbox :input-value="active" color="info" dense></v-checkbox>
              </v-list-item-action>
              <v-list-item-content class="px-1">
                <v-list-item-title
                  :class="active ? 'info--text' : ''"
                  class="font-weight-medium"
                  style="font-size: 12px !important"
                >
                  {{ filter.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import enums from "../../../../plugins/enums";
import { uniqBy } from "lodash";
import UserFilter from "./Filters/UserFilter.vue";
import ProjectSpaceCreatedByFilter from "./Filters/ProjectSpaceCreatedByFilter.vue";
import ProjectSpaceUpdatedByFilter from "./Filters/ProjectSpaceUpdatedByFilter.vue";
import SpaceTemplateFilter from "./Filters/SpaceTemplateFilter.vue";
import LevelFilter from "./Filters/LevelFilter.vue";
import BuildingFilter from "./Filters/BuildingFilter.vue";
import ManufacturerFilter from "./Filters/ManufacturerFilter.vue";
import ProductFamilyFilter from "./Filters/ProductFamilyFilter.vue?1";
import EquipmentCategoryFilter from "./Filters/EquipmentCategoryFilter.vue";
import InstallerFilter from "./Filters/InstallerFilter.vue";
import ProviderFilter from "./Filters/ProviderFilter.vue";
import ClientFilter from "./Filters/ClientFilter.vue";
import ProjectStatusFilter from "./Filters/ProjectStatusFilter.vue";
import RoleFilter from "./Filters/RoleFilter.vue";
import ExternalRoleFilter from "./Filters/ExternalRoleFilter.vue";
import ContractTypeFilter from "./Filters/ContractTypeFilter.vue";
import ContractOriginFilter from "./Filters/ContractOriginFilter.vue";
import ContractStatusFilter from "./Filters/ContractStatusFilter.vue";
import DownloadTypeFilter from "./Filters/DownloadTypeFilter.vue";

export default {
  name: "filter-manager",
  components: {
    UserFilter,
    ProjectSpaceCreatedByFilter,
    ProjectSpaceUpdatedByFilter,
    SpaceTemplateFilter,
    LevelFilter,
    BuildingFilter,
    ManufacturerFilter,
    ProductFamilyFilter,
    EquipmentCategoryFilter,
    InstallerFilter,
    ProviderFilter,
    ClientFilter,
    ProjectStatusFilter,
    RoleFilter,
    ExternalRoleFilter,
    ContractTypeFilter,
    ContractOriginFilter,
    ContractStatusFilter,
    DownloadTypeFilter,
  },
  data() {
    return {
      FILTER_TYPE: enums.FILTER_TYPE,
      activeFilters: [],
      activeFiltersModel: [],
      optionsData: [],
      selectedManufacturers: [],
      selectedManufacturersProductFamiliesList: [],
    };
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.activeFilters = this.cloneDeep(this.value);
    this.optionsData = this.cloneDeep(this.options);
  },
  methods: {
    onFilterClose(filter) {
      // this.$log("onFilterClose", this.cloneDeep(filter));
      filter.active = false;
      this.updateOpenedFilters();
    },
    filterHasChanged(filter, state) {
      if (filter.active && this.$refs[filter.ref] != null)
        this.$refs[filter.ref][0].clearSelection();

      this.$nextTick(() => {
        filter.active = !filter.active;
        this.updateOpenedFilters();
      });
    },
    updateOpenedFilters() {
      this.activeFiltersModel = this.activeFilters
        .map((elm, key) => (elm.active ? key : null))
        .filter((elm) => elm != null);
    },
    focus() {
      this.$refs.menuBtn.$el.focus();
    },
    generateSelectedProductFamiliesList() {
      var mans = this.cloneDeep(this.selectedManufacturers);
      mans = mans.map((m) => {
        m.productFamilies.unshift({
          id: -1,
          name: "Product Family not applicable",
          description: "Product Family not applicable",
        });
        return m;
      });
      this.$log(">>>>> mans", mans);
      var allFamilies = mans.flatMap((m) =>
        m.productFamilies.map((pf) => {
          return {
            ...pf,
            manufacturer: {
              id: m.id,
              name: m.name,
              abbreviatedName: m.abbreviatedName,
              logoUrl: m.logoUrl,
            },
          };
        })
      );
      this.$log(">>>>> allFamilies", this.cloneDeep(allFamilies));
      this.selectedManufacturersProductFamiliesList = uniqBy(allFamilies, "id");
      this.$log(
        ">>>>> selectedManufacturersProductFamiliesList",
        this.cloneDeep(this.selectedManufacturersProductFamiliesList)
      );
    },
  },
  watch: {
    value: {
      handler() {
        if (!this.isEqual(this.value, this.activeFilters)) {
          this.activeFilters = this.cloneDeep(this.value);
        }
      },
      deep: true,
    },
    activeFilters: {
      handler() {
        this.$emit("input", this.activeFilters);
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isEqual(this.options, this.optionsData)) return;
        this.optionsData = this.cloneDeep(this.options);
      },
      deep: true,
    },
    optionsData: {
      handler() {
        if (this.isEqual(this.options, this.optionsData)) return;
        this.$emit("update:options", this.optionsData);
      },
      deep: true,
    },
    selectedManufacturers: {
      handler() {
        this.$nextTick(() => {
          this.generateSelectedProductFamiliesList();
        });
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped></style>
